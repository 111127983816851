import { observer } from "mobx-react";
import { IProduct } from "../../stores/index.d";
import _ from "lodash";

import ProductGridItem from "./ProductGridItem";

type IProductGrid = {
    products: IProduct[];
    productCardClass: string;
};

const ProductGrid = observer(({ products, productCardClass }: IProductGrid) => {
    return (<>
        {
            products?.map((item: IProduct, idx: number) =>
                <div className={productCardClass??"col-lg-4 col-sm-6 col-md-4 products-col-item"} key={item.ProductId}>
                    <ProductGridItem product={item}></ProductGridItem>
                </div>

            )
        }
    </>);
});

export default ProductGrid;