import { useState } from "react";
import { Link } from "react-router-dom";
import { useStores } from "../../stores";
import { AppConfig } from "../../AppConfig";
import { ShowAlert } from "../../utilities/Functions";
import { isNumber } from "lodash";

const siteTitle = AppConfig?.Title;

export default function SignupPage() {
    const { authStore } = useStores();
    const { signup } = authStore;
    const [userId, setUserId] = useState(0);
    const [UserFirstName, setFirstName] = useState('');
    const [UserLastName, setLastName] = useState('');
    const [UserEmail, setEmailAddress] = useState('');
    const [UserMobileNumber, setMobileNo] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [Password, setPassword] = useState('');

    const handleCallback = (res: any) => {
        if (isNumber(res) && res > 0) {
            setUserId(res)
            ShowAlert.Success("Your account has been created. Now you can login with your credentials.");
        } else {
            ShowAlert.Error(res?.Message ?? "Request has been faild. Please try again.");
        }
    }

    const handleSignupFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!UserFirstName || !UserLastName || !UserEmail || !UserMobileNumber || !AccountName || !Password) {
            ShowAlert.Warning("Please fill out all * marked fields.");
            return;
        }

        let req = { UserFirstName, UserLastName, UserEmail, UserMobileNumber, AccountName, Md5Pass: btoa(Password) };
        signup(req, handleCallback);
    };

    return (
        <>
            <title>{siteTitle} - Signup</title>

            <section className="signup-area ptb-60">
                <div className="container">
                    {userId ?
                        <>
                            <div className="card text-center border-0 mt-5">
                                <div className="card-body">
                                    <h5 className="card-title">Hello! {UserLastName}, {UserFirstName}</h5>
                                    <p className="card-text"><i className="fa fa-check-circle fa-3x text-success"></i>
                                        <span className="h5" style={{ verticalAlign: "super" }}>You account has been created successfully.</span>
                                    </p>
                                    <Link to={`/login`} className="btn btn-primary">
                                        Login to your account
                                    </Link>
                                </div>
                            </div>
                        </> : <div className="signup-content">
                            <div className="section-title">
                                <h2><span className="dot"></span>
                                    <span id="lbl_singup_crtaccount">
                                        Create an Account
                                    </span>
                                </h2>
                            </div>

                            <form className="signup-form" autoComplete="off" onSubmit={handleSignupFormSubmit}>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6'>
                                        <div className="form-group">
                                            <label>
                                                First Name
                                                <span className="required-field">*</span></label>
                                            <input type="text" className="form-control" placeholder="Enter first name" id="FirstName" name="FirstName"
                                                required={true}
                                                value={UserFirstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6'>
                                        <div className="form-group">
                                            <label>
                                                Last Name
                                                <span className="required-field">*</span></label>
                                            <input type="text" className="form-control" placeholder="Enter last name" id="LastName" name="LastName"
                                                required={true}
                                                value={UserLastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6'>
                                        <div className="form-group">
                                            <label>
                                                Email
                                                <span className="required-field">*</span></label>
                                            <input type="email" className="form-control" placeholder="Enter email" id="EmailAddress" name="EmailAddress"
                                                required={true}
                                                value={UserEmail}
                                                onChange={(e) => setEmailAddress(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6'>
                                        <div className="form-group">
                                            <label>
                                                Phone Number
                                                <span className="required-field">*</span></label>
                                            <input type="tel" className="form-control" placeholder="Enter phone number" id="PhoneNumber" name="PhoneNumber"
                                                required={true}
                                                value={UserMobileNumber}
                                                onChange={(e) => setMobileNo(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label>
                                                Password
                                                <span className="required-field">*</span>
                                            </label>
                                            <input type="password" className="form-control" placeholder="Enter your password" id="Password" name="Password"
                                                required={true}
                                                value={Password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className='col-12'>
                                        <div className="form-group">
                                            <label>
                                                Account Name
                                                <span className="required-field">*</span>
                                            </label>
                                            <input type="text" className="form-control" placeholder="Enter account name" id="AccountName" name="AccountName"
                                                required={true}
                                                value={AccountName}
                                                onChange={(e) => setAccountName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-primary" id="lbl_singup_submitbnt">
                                    Signup
                                </button>

                                <Link to={`/login`} className="return-store" id="lbl_singup_loginacnt">
                                    or Login to your account
                                </Link>
                            </form>
                        </div>}
                </div >
            </section >
        </>
    );
}