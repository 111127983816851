import OwlCarousel from 'react-owl-carousel3';
import "./DashboardPage.scss";
import { useEffect, useMemo, useState } from 'react';
import defaultImgNa from "../../resources/images/image-na-alt.png";
import _ from 'lodash';
import { Link } from 'react-router-dom';
import RelatedProducts from '../../component/Products/RelatedProducts';
import { observer } from 'mobx-react';
import { useStores } from '../../stores';
import { AppConfig } from "../../AppConfig";

const siteTitle = AppConfig?.Title;

function importAllBannerImages(img: any) {
    return img.keys().map(img);
}

const bannerImages = importAllBannerImages(require.context('../../resources/images/main-banner', false, /\.(png|jpe?g|svg)$/));
const bannerOptions = {
    items: 1,
    loop: true,
    nav: false,
    dots: true,
    animateOut: "slideOutDown",
    animateIn: "slideInDown",
    smartSpeed: 750,
    autoplay: true,
    autoplayHoverPause: true,
    navText: [
        "<i class='fas fa-arrow-left'></i>",
        "<i class='fas fa-arrow-right'></i>"
    ]
}

const DashboardPage = observer(() => {
    const { shopStore } = useStores();
    const { departments, products } = shopStore;    
    const [isMobileResolution, setIsMobileResolution] = useState(window.innerWidth < 768);

    // useEffect(() => {
    //     fetchFrequentProducts();
    // });

    const populerProducts = useMemo(() => {
        return products.slice(0, 6);
    }, [products])

    const scrollToTop = (e: any) => {
        e.preventDefault();
        document.getElementById("linkScrollToTop")?.click();
    }

    useEffect(() => {        
        const handleResize = () => {
            setIsMobileResolution(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
        <title>{siteTitle}</title>
            <div className='container-fluid mt-2'>
                <OwlCarousel className="home-slides-three owl-theme" {...bannerOptions}>
                    {
                        bannerImages.map((imgUrl: string, index: number) => {
                            return (
                                <div key={index} className="main-banner" style={{ backgroundImage: `url(${imgUrl})` }}>
                                    <div className="d-table">
                                        <div className="d-table-cell">
                                            <div className="container">
                                                <div className="main-banner-content white-color">
                                                    <span className="">Best Deals</span>
                                                    <h1 className="">Quality Products</h1>
                                                    <p className="">Grab them with discount price</p>
                                                    <Link className="btn btn-primary " to={"/al-prods/pplr-prod"}>Shop Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </OwlCarousel>
            </div>

            <section className="category-boxes-area pt-60">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            <span className="dot"></span>
                            <span id="lbl_popct_category"> Popular Categories!</span>
                        </h2>
                    </div>
                    <div className="row">
                        {departments?.length ? departments.map((item, index) => (
                            <div className="col-lg-3 col-sm-6" key={item + index}>
                                <div className="category-boxes" onClick={scrollToTop}>
                                    <img src={defaultImgNa} alt="image" />
                                    <div className="content">
                                        <h3>{item}</h3>
                                        <p>
                                            <span >Category Note</span>
                                            <span >Description</span>
                                        </p>
                                        <Link className="shop-now-btn" to={"al-prods/" + item}>Shop Now</Link>
                                    </div>
                                </div>
                            </div>)) : null
                        }
                    </div>
                </div>
            </section>
            <section className="category-boxes-area pt-60">
                <RelatedProducts products={populerProducts} sectionHeader={"Popular Products"} gridStyleClass={isMobileResolution ? "" : "products-col-six"} />
            </section>
        </>
    );
})

export default DashboardPage;