import _, { isDate, toNumber } from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import { Api } from "../services";
import { Config } from "../services/Config";
import { DeliveryMethodEnum, ICartItem, IRootStore } from "./index.d";
import { ShowAlert } from "../utilities/Functions";
import { WebStorage } from "../utilities";
import { WEB_STORAGE_NAMES } from "../utilities/Constants";

const CREATE_ORDER = Config.REST_URIS.Order.create;

export default class CartStore {
  orderNotes: string = "";
  expectedDeliveryDate: string = "";
  deliveryMethod: DeliveryMethodEnum = DeliveryMethodEnum.Delivery;

  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  addItemToCart = (cartItem: ICartItem, notFromCartPage: boolean) => {
    if (!cartItem) return;
    let cartItems = this.getCartItems() ?? [];

    try {
      //--check if product already exists
      let existCartItem = cartItems?.find((p: ICartItem) => p.ProductId === cartItem.ProductId);
      if (existCartItem) {
        existCartItem.inStock = cartItem.inStock ?? 0;
        existCartItem.quantity = cartItem.quantity;
        if (notFromCartPage)
          ShowAlert.Default("Product quantity updated.");
      } else {
        if (!cartItem.inStock)
          cartItem.inStock = 0;
        cartItems.push(cartItem);
        if (notFromCartPage)
          ShowAlert.Success("Product added to cart.");
      }
      WebStorage.setStorage(WEB_STORAGE_NAMES.CartItems, cartItems);
      this.cartItems = cartItems;
      this.cartItemsCount = cartItems.length;
    }
    catch (err) {
      ShowAlert.Error("An error occured. Please try again.");
    }
  };

  getOrderNumber = () => {
    return WebStorage.getStorage(WEB_STORAGE_NAMES.OrderNumber);
  }

  getCartItems = () => {
    return WebStorage.getStorage(WEB_STORAGE_NAMES.CartItems);
  }

  orderNumber: string = this.getOrderNumber();
  cartItems: ICartItem[] = this.getCartItems();
  cartItemsCount: number = this.cartItems ? this.cartItems.length : 0;

  setOrderNumber = (orderNo: string) => {
    this.clearCartItems(0);
    this.orderNumber = orderNo;
    WebStorage.setStorage(WEB_STORAGE_NAMES.OrderNumber, orderNo);
  }

  getCartItem = (productId: number) => {
    return this.cartItems?.find(p => p.ProductId === productId);
  }

  clearCartItems = (productId: number) => {
    if (productId) {
      let removeIndex = this.cartItems?.findIndex((p) => p.ProductId === productId);
      if (removeIndex >= 0) {
        this.cartItems.splice(removeIndex, 1);
        WebStorage.setStorage(WEB_STORAGE_NAMES.CartItems, this.cartItems);
      }
    } else {
      WebStorage.setStorage(WEB_STORAGE_NAMES.OrderNumber, "");
      WebStorage.setStorage(WEB_STORAGE_NAMES.CartItems, []);
      this.cartItems = [];
      this.cartItemsCount = 0;
      this.orderNotes = "";
      this.expectedDeliveryDate = "";
      this.deliveryMethod = DeliveryMethodEnum.Delivery;
    }
  }

  setDeliveryDetails = (value: string, field: string) => {
    switch (field) {
      case "dt":
        this.deliveryMethod = toNumber(value);
        break;
      case "dd":
        this.expectedDeliveryDate = value;
        break;
      case "dn":
        this.orderNotes = value;
        break;
    }
  }

  checkoutCartItems = (callback: Function) => {
    const { getShopId, getUserId } = this.rootStore.authStore;

    if (!this.deliveryMethod) {
      ShowAlert.Warning("Please choose a delivery type.");
      return;
    }

    if (!this.expectedDeliveryDate || !isDate(new Date(this.expectedDeliveryDate))) {
      ShowAlert.Warning(`Please choose a expected ${this.deliveryMethod} date.`);
      return;
    }

    if (this.cartItems.length) {
      // const lessInStockItems = this.cartItems.filter(p => p.quantity > p.inStock)
      // if (lessInStockItems.length) {
      //   ErrorException({ message: "Please add in stock value aganist product." });
      //   return;
      // }

      Api.post(
        CREATE_ORDER,
        {
          orderNumber: this.orderNumber,
          newSalesProducts: toJS(this.cartItems),
          userId: getUserId(),
          shopId: getShopId(),
          orderNotes: this.orderNotes,
          expectedDate: this.expectedDeliveryDate,
          deliveryMethod: this.deliveryMethod
        },
        (res: any) => {
          if (res) {
            this.rootStore.orderStore.setOrderNumber(res);
            this.clearCartItems(0);
            callback();
            ShowAlert.Success(`Order ${this.orderNumber ? "updated" : "created"} successfully.`);
          } else {
            ShowAlert.Error("Order create request failed. Please contact administrator.");
          }
        }
      );
    } else {
      ShowAlert.Warning("Your cart is empty.");
    }
  }
}
