import moment from "moment";
import { toast, ToastOptions } from "react-toastify";

const alertOpts = { theme: "light", className: "mt-5" } as ToastOptions;

export const ShowAlert = {
  Default: (content: any) => toast(content, alertOpts),
  Warning: (content: any) => toast.warn(content, alertOpts),
  Info: (content: any) => toast.info(content, alertOpts),
  Success: (content: any) => toast.success(content, alertOpts),
  Error: (content: any) => toast.error(content, alertOpts)
};

export const formatter = (price: any) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(price);

export const formatDate = (input: any, format: string = "DD/MM/YYYY hh:MM") => moment(input).format(format)
