import { IProduct } from "../../stores/index.d";
import ProductGrid from "./ProductGrid";

type IRelatedProducts = {
    products: IProduct[];
    gridStyleClass: string;
    sectionHeader: string;
}
const RelatedProducts = ({ products, gridStyleClass, sectionHeader }: IRelatedProducts) => {
    return (
        <>
            <section className="products-area pt-60 pb-30">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            <span className="dot"></span>
                            <span>
                                {sectionHeader}
                            </span>
                        </h2>
                    </div>
                    <div className={`products-collections-listing row ${gridStyleClass}`}>
                        <ProductGrid products={products} productCardClass="col-lg-3 col-sm-6 col-md-4 products-col-item" />
                    </div>
                </div>
            </section>
        </>
    );

}


export default RelatedProducts;