import { IUser, IRootStore, IStore } from "./index.d";
import { makeAutoObservable } from "mobx";
import { Api } from "../services";
import { Config } from "../services/Config";
import { WebStorage } from "../utilities";
import { WEB_STORAGE_NAMES } from "../utilities/Constants";

const GET_USERS = Config.REST_URIS.User.allUser;
const GET_VERIFICATION = Config.REST_URIS.Device.ConnectionCheck;
const GET_ALL_STORES = Config.REST_URIS.Auth.getUserStores;
export default class UserStore {
  users: Array<IUser> = [];  
  storesList: Array<IStore> = WebStorage.getStorage(WEB_STORAGE_NAMES.StoresList);
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchUsers = () => {
    let date = new Date().toLocaleDateString("en-CA");
    Api.get(GET_USERS(date, this.rootStore.SerialNo), (res: any) =>
      console.log(res)
    );
  };

  verifyDevice = () => {
    Api.get(GET_VERIFICATION(this.rootStore.SerialNo), (res: any) =>
      console.log(res)
    );
  };
  getStores = (userId: number, tenantId: number) => {
    // this.storesList = WebStorage.getStorage(WEB_STORAGE_NAMES.StoresList);
    // if(this.storesList?.length) return;
    Api.get(GET_ALL_STORES(userId, tenantId), (res: any) => {
      WebStorage.setStorage(WEB_STORAGE_NAMES.StoresList, res);
      this.storesList = res;
    });
  };  
}
