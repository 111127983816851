import React from "react";

import {
  IShopStore,
  ICartStore,
  IUserStore,
  IAuthStore,
  IRootStore,
  IOrderStore
} from "./index.d";

import AuthStore from "./AuthStore";
import CartStore from "./CartStore";
import ShopStore from "./ShopStore";
import UserStore from "./UserStore";
import OrderStore from "./OrderStore";

class RootStore implements IRootStore {
  shopStore: IShopStore;
  cartStore: ICartStore;
  userStore: IUserStore;
  authStore: IAuthStore;
  orderStore: IOrderStore;
  SerialNo: string = "6647164900009";
  Shop: string = "14";

  constructor() {
    this.shopStore = new ShopStore(this);
    this.cartStore = new CartStore(this);
    this.userStore = new UserStore(this);
    this.authStore = new AuthStore(this);
    this.orderStore = new OrderStore(this);
  }
}

const StoresContext = React.createContext(new RootStore());

// this will be the function available for the app to connect to the stores
export const useStores = () => React.useContext(StoresContext);
