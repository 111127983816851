import _, { toNumber } from "lodash";
import { IRootStore, IProduct } from "./index.d";
import { Config } from "./../services/Config";
import { makeAutoObservable } from "mobx";
import { Api } from "../services";

const GET_PRODUCTS = Config.REST_URIS.Product.Products;
const GET_PRODUCTS_BY_ACC = Config.REST_URIS.Product.ProductsByAcc;
// const GET_FREQUENT_PRODUCTS = Config.REST_URIS.Product.getFrequentProducts;

export default class ShopStore {
  products: Array<IProduct> = [];
  departments: Array<string> = [];
  selected_dept: string | null = null;

  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchProducts = async (warehouseId: any) => {
    const { getShopId, getAccountId } = this.rootStore.authStore;
    const accountId = getAccountId();
    const url = accountId ? GET_PRODUCTS_BY_ACC(warehouseId ?? getShopId(), accountId) : GET_PRODUCTS(warehouseId ?? getShopId());
    this.rootStore.authStore.toggleLoader(true);
    Api.get(url, (res: any) => {
      this.rootStore.authStore.toggleLoader(false);
      if (res?.Products) {
        this.products = _.sortBy(res.Products, ["SortOrder", "Name"]);
        let dept: Array<IProduct>;
        dept = _.uniqBy(res.Products, function (ele) {
          return JSON.stringify(_.pick(ele, ["DepartmentName"]));
        });
        dept = _.sortBy(dept, (o) => o.SortOrder);
        this.departments = _.map(dept, "DepartmentName");
      }
    });
  };

  // fetchFrequentProducts = async () => {
  //   const tenantId = process.env.REACT_APP_TENANT_ID;
  //   const warehouseId = this.rootStore.authStore?.getShopId();
  //   if(!tenantId|| !warehouseId) return;
  //   Api.get(GET_FREQUENT_PRODUCTS(toNumber(tenantId), warehouseId), 
  //   (res: IFrequentProduct[]) => {
  //     this.frequentProducts = res;
  //   });
  // };

  onSelectionDept = (name: string | null) => {
    this.selected_dept = name;
  };

  getProduct = (ProductId: any) => {
    return _.find(this.products, { ProductId });
  };

  getProductBySku = (SKUCode: any) => {
    return _.find(this.products, { SKUCode });
  };
}
