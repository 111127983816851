import becscoConfig from "../src/becsco-config.json";
import icestoneConfig from "../src/icestone-config.json"

const appInstance = process.env.REACT_APP_SITE_INSTANCE;

const getConfig = () => {
  switch (appInstance) {
    case "becsco":
      return becscoConfig;
    case "icestone":
      return icestoneConfig;
  }
}

export const AppConfig = getConfig();