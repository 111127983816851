import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isDate } from "lodash";
import { useStores } from "../../stores";
import { ICartItem } from "../../stores/index.d";
import { ShowAlert, formatter } from "../../utilities/Functions";
import { DeliveryMethodEnum } from "../../stores/index.d";
import moment from "moment";
import { Button, Modal } from 'react-bootstrap';
import { AppConfig } from "../../AppConfig";
import defaultImgNa from "../../resources/images/image-na-alt.png";

const siteTitle = AppConfig?.Title;
const showInStock = AppConfig?.CollectInStockFromStore;
const enableOrderCollection = AppConfig?.EnableOrderCollection;
const enableOrderDelivery = AppConfig?.EnableOrderDelivery;

const CartViewPage = observer(() => {
    let navigate = useNavigate();

    const { cartStore, shopStore, orderStore } = useStores();
    const { orderNumber, cartItems, clearCartItems, addItemToCart, deliveryMethod, expectedDeliveryDate, orderNotes, setDeliveryDetails, checkoutCartItems } = cartStore;
    const { fetchProducts, products } = shopStore;
    const { setOrderNumber } = orderStore
    const [cartTotal, setCartTotal] = useState(0);
    const [cartSubTotal, setCartSubTotal] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!enableOrderDelivery && enableOrderCollection)
            setDeliveryDetails(DeliveryMethodEnum.Collection, "dt");

        if (!products.length)
            fetchProducts();
    }, []);

    useEffect(() => {
        setCartSubTotal(cartItems?.reduce((a, b) => a + (b.quantity * b.SellPrice), 0));
    }, [cartItems]);

    useEffect(() => {
        setCartTotal(cartSubTotal);
    }, [cartSubTotal]);

    const getSubTotal = (qty: number, price: number) => { return qty * price };

    const setItemQty = (cartItem: ICartItem, addOnQty: number, isStockQty: boolean) => {
        if (isStockQty) {
            if (addOnQty < 0 && cartItem.inStock <= 0) return;
            cartItem.inStock += addOnQty;
        } else {
            if (addOnQty < 0 && cartItem.quantity <= 1) return;
            cartItem.quantity += addOnQty;
        }

        addItemToCart(cartItem);
    }

    const handleCheckout = () => {
        setShowModal(false);
        checkoutCartItems(() => navigate("/order"));
    }

    const handleConfirmCheckout = () => {
        if (!deliveryMethod) {
            ShowAlert.Warning("Please choose a delivery type.");
            return;
        }

        if (!expectedDeliveryDate || !isDate(new Date(expectedDeliveryDate))) {
            ShowAlert.Warning(`Please choose a expected ${DeliveryMethodEnum[deliveryMethod]} date.`);
            return;
        }

        if (showInStock && cartItems.find(p => p.inStock < p.quantity)) {
            ShowAlert.Info("Please make sure you updated in-stock value aganist product.");
        }

        setShowModal(true);
    }

    const onOrderDetailsClick = (orderNumber: string) => {
        setOrderNumber(orderNumber);
        navigate("/order");
      };

    // const cartItems = useMemo(() => { return getCartItems().map((p:any) => p.cartItem); }, []);

    return (<>
        <title>{siteTitle} - Cart</title>
        <section className="cart-area mt-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        {cartItems?.length ? <div className="d-flex justify-content-between">
                            <h4 className="w-100"> { orderNumber? <>Edit Order <a href="#" title="View order details" className="d-inline" onClick={() => onOrderDetailsClick(orderNumber)}><span className="badge bg-primary p-2">{orderNumber}</span></a></>  : "Cart Items" }</h4>
                            <div className="flex-shrink-0">
                                <Link className="btn btn-outline-danger" to={""} onClick={() => clearCartItems()}>Clear Cart</Link>
                            </div>
                        </div> : null}
                        <div>
                            {cartItems?.length > 0 ? <><div className="cart-table table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" id="lbl_hdr_cart_prod">Product</th>
                                            <th scope="col" id="lbl_hdr_cart_name">Name</th>
                                            <th scope="col" id="lbl_hdr_cart_vari">Department</th>
                                            <th scope="col">SKU Code</th>
                                            {showInStock ? <th scope="col">In Stock</th> : null}
                                            <th scope="col">Unit Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Sub Total</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems.map((cartItem: ICartItem) => (
                                            <tr key={cartItem.ProductId}>
                                                <td className="product-thumbnail">
                                                    <a href={"/prod/" + cartItem.ProductId}>
                                                        <img src={defaultImgNa} alt="Product image" />
                                                    </a>
                                                </td>
                                                <td className="product-name">
                                                    <a href={"/prod/" + cartItem.ProductId}>{cartItem.Name}</a>
                                                    <p>{cartItem.Description}</p>
                                                </td>
                                                <td className="product-name">
                                                    {cartItem.DepartmentName}
                                                </td>
                                                <td className="product-name">
                                                    {cartItem.SKUCode}
                                                </td>
                                                {showInStock ? <td className="product-quantity">
                                                    <div className="input-counter">
                                                        <span className="minus-btn" onClick={() => {
                                                            setItemQty(cartItem, -1, true);
                                                        }}>
                                                            <i className="fas fa-minus"></i>
                                                        </span>
                                                        <input type="text" value={cartItem.inStock} />
                                                        <span className="plus-btn" onClick={() => {
                                                            setItemQty(cartItem, 1, true);
                                                        }}>
                                                            <i className="fas fa-plus"></i>
                                                        </span>
                                                    </div>
                                                </td> : null}
                                                <td className="product-price">
                                                    <span className="unit-amount">{formatter(cartItem.SellPrice)}</span>
                                                </td>
                                                <td className="product-quantity">
                                                    <div className="input-counter">
                                                        <span className="minus-btn" onClick={() => {
                                                            setItemQty(cartItem, -1, false);
                                                        }}>
                                                            <i className="fas fa-minus"></i>
                                                        </span>
                                                        <input type="text" value={cartItem.quantity} />
                                                        <span className="plus-btn" onClick={() => {
                                                            setItemQty(cartItem, 1, false);
                                                        }}>
                                                            <i className="fas fa-plus"></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="product-subtotal">
                                                    <span className="subtotal-amount">{formatter(getSubTotal(cartItem.quantity, cartItem.SellPrice))}</span>
                                                    <a className="remove" href="" onClick={() => clearCartItems(cartItem.ProductId)} title="Remove item">
                                                        <i className="fa fa-trash fs-4"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div></> : <><div className="h4 text-center mt-5">Your <i className="fas fa-shopping-cart fa-2x text-warning"></i>cart is empty.</div></>}

                            <div className="cart-buttons">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-9">
                                        <div className="continue-shopping-box">
                                            <Link className="btn btn-light" to="/al-prods/all">{ cartItems?.length ? "Add items to Cart" : "Continue Shopping" }</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {cartItems?.length ? <>
                                <div className="cart-totals">

                                    <div className="row mb-4">
                                        {enableOrderCollection || enableOrderDelivery ?
                                            <>
                                                <label className="fw-bold">Delivery Method *</label>
                                                <div className="btn-group btn-group-toggle mb-2" data-toggle="buttons">
                                                    {enableOrderDelivery ? <label className={`btn btn-secondary${deliveryMethod == DeliveryMethodEnum.Delivery ? " active" : ""}`}>
                                                        <input type="radio" name="deliveryType" id="deliveryTypeDel" checked={deliveryMethod == DeliveryMethodEnum.Delivery} onChange={() => setDeliveryDetails(DeliveryMethodEnum.Delivery, "dt")} /> Delivery
                                                    </label> : null}
                                                    {enableOrderCollection ? <label className={`btn btn-secondary${deliveryMethod == DeliveryMethodEnum.Collection ? " active" : ""}`}>
                                                        <input type="radio" name="deliveryType" id="deliveryTypeCol" checked={deliveryMethod == DeliveryMethodEnum.Collection} onChange={() => setDeliveryDetails(DeliveryMethodEnum.Collection, "dt")} /> Collection
                                                    </label> : null}
                                                </div>
                                            </> : null}
                                        <div className="form-group mb-2">
                                            <label className="form-label fw-bold" htmlFor="deliveryDate">Expected {DeliveryMethodEnum[deliveryMethod]} date *</label>
                                            <input type="date" className="form-control" min={moment().format("YYYY-MM-DD")} value={expectedDeliveryDate} id="deliveryDate" required onChange={(e) => setDeliveryDetails(e.target.value, "dd")} />
                                        </div>
                                        <div className="form-group mb-2">
                                            <label className="form-label fw-bold" htmlFor="deliveryDate">{DeliveryMethodEnum[deliveryMethod]} note</label>
                                            <textarea id="deliveryNote" value={orderNotes} rows={6} onChange={(e) => setDeliveryDetails(e.target.value, "dn")} className="form-control" placeholder="Enter your delivery note if any"></textarea>
                                        </div>
                                    </div>

                                    <h3>Cart Totals</h3>
                                    <ul>
                                        <li>Subtotal <span>{formatter(cartSubTotal)}</span>
                                        </li>
                                        <li>Total <span>
                                            <b>{formatter(cartTotal)}</b>
                                        </span>
                                        </li>
                                    </ul>
                                    <div className="d-grid">
                                        <button className="btn btn-light" type="button" onClick={handleConfirmCheckout}> { orderNumber? "Update Order" : "Place Order" }</button>
                                    </div>

                                </div></> : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Order Confirmation!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please press Yes to { orderNumber? "update this order" : "place this order" }.</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { handleCheckout(); }}>
                    Yes
                </Button>
                <Button variant="danger" onClick={() => setShowModal(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
})

export default CartViewPage;
