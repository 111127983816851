import { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useStores } from "../../stores";

export interface IRequireAuth {
  children: ReactElement;
}

export default function RequireAuth({ children }: IRequireAuth) {
  const { authStore } = useStores();

  const { loggedUser } = authStore;
  const location = useLocation();

  return loggedUser?.UserId ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
