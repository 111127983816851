import _ from "lodash";
import { IRootStore, ISale } from "./index.d";
import { Config } from "./../services/Config";
import { makeAutoObservable } from "mobx";
import { Api } from "../services";
import { ShowAlert } from "../utilities/Functions";

const GET_SALES = Config.REST_URIS.Order.completedsales;
const GET_ALL_SALES = Config.REST_URIS.Order.allcompletedorder;
const ORDER_DETAIL = Config.REST_URIS.Order.detailByNo;

export default class OrderStore {
  sales: Array<ISale> = [];
  totalCount: number = 0;
  orderDetails: any = {};
  orderNumber: string = "";
  isEditOrderRequest: boolean = false;

  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchCompletedSales = async (orderNo: string) => {
    Api.get(GET_SALES(orderNo), (res: any) => {
      if (res.length !== 0) {
        this.sales = res;
      } else {
        ShowAlert.Warning("No order data available.");
      }
    });
  };

  fetchAllSalesComplete = async (currentPage: number) => {
    const { getShopId, getUserId, getTenantId, getAccountId } = this.rootStore.authStore;

    Api.post(
      GET_ALL_SALES,
      {
        // ShopId: getShopId(),
        TenantId: getTenantId(),
        WarehouseId: getShopId(),
        AccountId: getAccountId(),
        UserId: getUserId(),
        PageSize: 10,
        TotalCount: 0,
        PageNumber: currentPage,
        TransactionType: 2
      },
      (res: any) => {
        if (res?.Orders.length !== 0) {
          this.sales = res.Orders;
          this.totalCount = res.TotalCount;
        }
        else {
          ShowAlert.Warning("No order data available.");
        }
      }
    );
  };

  setOrderNumber = (input: string, isEditRequest: boolean) => {
    this.orderNumber = input;
    this.isEditOrderRequest = isEditRequest;
  }

  getOrderDetails = async (callback: Function) => {
    if (!this.orderNumber) return;
    const { getShopId } = this.rootStore.authStore;

    this.rootStore.authStore.toggleLoader(true);
    Api.get(ORDER_DETAIL(this.orderNumber, getShopId()), (res: any) => {
      this.orderDetails = res;
      callback();
    }).finally(() => { this.rootStore.authStore.toggleLoader(false); });
  };
}
