import { map } from "lodash";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import { AppConfig } from "../../AppConfig";
import { Config } from "../../services/Config";
import Pagination from "../../component/Shared/Pagination";
import { formatter, formatDate } from "../../utilities/Functions";
import _ from "lodash";

interface Column {
  id: "OrderNumber" | "OrderTotal" | "OrderPlacedDate" | "Status" | "ProductsCount";
  label: string;
}

const columns: readonly Column[] = [
  { id: "OrderNumber", label: "Order Number" },
  { id: "OrderTotal", label: "Order Total" },
  { id: "OrderPlacedDate", label: "Order Placed" },
  { id: "Status", label: "Status" },
  { id: "ProductsCount", label: "Items" },
];

function createData(
  OrderID: number,
  OrderNumber: string,
  OrderTotal: string,
  OrderPlacedDate: string,
  Status: string,
  ProductsCount: string
) {
  return { OrderID, OrderNumber, OrderTotal, OrderPlacedDate, Status, ProductsCount };
}

const siteTitle = AppConfig?.Title;

const SalePageComplete = observer(() => {
  const navigate = useNavigate();
  const [orderNo, setOrderNo] = useState("");
  const { orderStore } = useStores();

  const { fetchCompletedSales, sales, fetchAllSalesComplete, totalCount, setOrderNumber } = orderStore;

  const completedOrders = useMemo(
    () =>
      map(sales, ({ OrderID, OrderNumber, OrderTotal, OrderPlacedDate, Status, ProductsCount }) => {
        return createData(OrderID, OrderNumber, OrderTotal, OrderPlacedDate, Status, ProductsCount);
      }),
    [sales]
  );

  const onChangeOrder = (event: any) => {
    setOrderNo(event.target.value);
  };

  const handleOrder = (e: any) => {
    if (e.key === "Enter") {
      fetchCompletedSales(orderNo);
      setOrderNo("");
      document.getElementById("scanproducts")?.focus();
    }
  };

  const onDownloadClick = (row: any) => {
    const { OrderID } = row;
    window.open(Config.ROOT_URL + Config.REST_URIS.Order.invoiceDownload(OrderID));
  };

  const onOrderDetailsClick = (orderNumber: string, isEditRequest: boolean) => {
    setOrderNumber(orderNumber, isEditRequest);
    navigate("/order");
  };

  const onPageChanged = (data: any) => {
    const { currentPage } = data;
    fetchAllSalesComplete(currentPage ? currentPage : 1);
  };

  return (
    <>
      <title>{siteTitle} - Orders</title>
      <section className="products-collections-area ptb-60">
        <div className="container">
          <div className="section-title mb-0">
            <h3>Sales Orders</h3>
          </div>
          <div className="form-group">
            <label className="fw-bold">Search Order</label>
            <div className="input-group rounded">
              <input type="search" value={orderNo} onChange={onChangeOrder} onKeyDown={handleOrder} className="form-control rounded" placeholder="Search by Order number" aria-label="Search" aria-describedby="search-addon" />
              <span className="input-group-text border-0" id="search-addon">
                <i className="fas fa-search"></i>
              </span>
            </div>
          </div>

          <div className="table-container">
            <table className="table table-hover">
              <thead>
                <tr key="header-row">
                  {columns.map((column) => (
                    <th key={column.id + column.label} scope="col">
                      {column.label}
                    </th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {completedOrders.map((row, rowIndex) => {
                  if (row) {
                    return (
                      <tr key={rowIndex}>
                        {columns.map((column, colIndex) => {
                          const value = row[column.id];

                          return <td key={rowIndex + colIndex}>
                            {column.id === "OrderTotal" ? formatter(value) : column.id === "OrderPlacedDate" ? formatDate(value) : value}
                            {column.id === "OrderNumber" ?
                              <div className="btn-group">
                                <Link to={"#"} onClick={e => {
                                  e.preventDefault();
                                  onOrderDetailsClick(row.OrderNumber, false);
                                }} title="View order details" className="d-inline p-2"><i className="fa fa-solid fa-paper-plane fs-5"></i></Link>
                               { _.isEqual(row.Status, "Active")? <Link to={"#"} onClick={e => {
                                  e.preventDefault();
                                  onOrderDetailsClick(row.OrderNumber, true);
                                }} title="Edit order" className="d-inline p-2"><i className="fas fa-edit fs-5 text-secondary"></i></Link> : null }
                              </div>
                              : null}
                          </td>;
                        })}
                        <td>
                          <a href="#" onClick={() => onDownloadClick(row)} title="Download invoice">
                            <i className="fa fs-5 fa-file-download text-info"></i>
                          </a>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>

            <div className="justify-content-center align-self-center text-center">
              <Pagination
                totalRecords={totalCount}
                pageNeighbours={1}
                onPageChanged={onPageChanged}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
});
export default SalePageComplete;