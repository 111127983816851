import ScrollToTop from "./ScrollToTop";


const Footer = () => {

    return (
    <>
        <ScrollToTop />
    </>);
}

export default Footer;