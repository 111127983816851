import { observer } from "mobx-react";
import { ICartItem, IProduct } from "../../stores/index.d";
import { Link } from "react-router-dom";
import { useStores } from "../../stores";
import { useMemo, useState } from "react";
import { toNumber } from "lodash";
import _ from "lodash";
import { formatter } from "../../utilities/Functions";
import defaultImgNa from "../../resources/images/image-na-alt.png";
import palletImg from "../../resources/images/pallet.png";

type IProductGridItem = { product: IProduct }

export const getShortDescription = (inputString: string, length: number) => {
    length = length ?? 50;

    if (inputString != undefined && inputString != null && inputString.length > 0) {
        let newString = inputString.length > length ? (inputString.substring(0, length) + '...') : (inputString.substring(0, length))
        return newString;
    } else {
        return "";
    }
}

const ProductGridItem = observer(({ product }: IProductGridItem) => {
    const { cartStore } = useStores();
    const { getCartItem, addItemToCart } = cartStore;
    const [itemQty, setItemQty] = useState(1);

    // const itemsInCart = useMemo(() => {
    //     return _.map(cartItems, "productId");
    // }, [cartItems]);

    const scrollToTop = (e: any) => {
        // e.preventDefault();
        document.getElementById("linkScrollToTop")?.click();
    }

    const handleAddToCart = (prod: any) => {
        let cartItem: ICartItem = getCartItem(prod.ProductId);
        if (cartItem) {
            cartItem.quantity += itemQty;
        } else {
            cartItem = prod;
            cartItem.quantity = itemQty;
        }

        addItemToCart(cartItem, true);
        setItemQty(1);
    }

    return (<div className="single-product-box">
        <div className="product-image">
            {(() => {
                return (
                    <Link to={"/prod/" + product.ProductId} >
                        {/* {product?.ProductImagePath ? <img src={product?.ProductImagePath} alt="Product image" /> :
                            <i className="fa fa-solid fa-image fa-10x text-secondary"></i>
                        } */}
                        <img onClick={scrollToTop} src={product?.ProductImagePath ?? defaultImgNa} alt="Product image" />
                        <span className="position-absolute top-0 start-50 badge translate-middle bg-dark-red fs-6">
                            {product.DepartmentName}
                            <span className="visually-hidden">Department</span>
                        </span>
                        {product.ProcessByPallet ? <div className="pallet-img-holder" onClick={(e: any) => {
                            e.preventDefault();
                            const palletQty = toNumber(product.CasesPerPallet ?? 0);
                            if (palletQty)
                                setItemQty(palletQty);
                        }}>
                            <img src={product?.ProductImagePath ?? palletImg} alt="Pallet image" className="pallet-img" />
                            <span className="position-absolute top-0 start-50 badge translate-middle bg-dark-red fs-6 fw-normal">
                                {product.CasesPerPallet ?? 0}
                                <span className="visually-hidden">Pallet Cases</span>
                            </span>
                        </div> : null}
                    </Link>
                );
            })()}
        </div>

        <div className="product-content">
            <h3>
                <Link to={"/prod/" + product.ProductId} >
                    {getShortDescription(product.Name, 45)}
                </Link>
            </h3>

            <div className="product-price">
                <span className="new-price">
                    {formatter(product.SellPrice)}
                </span>
            </div>
            {(() => {
                return (<div className="product-details-content">
                    <div className="product-add-to-cart mt-4 d-flex">
                        <div className="input-counter">
                            <span
                                className="minus-btn"
                                onClick={() => {
                                    if (itemQty > 1)
                                        setItemQty(itemQty - 1);
                                }}
                            >
                                <i className="fas fa-minus"></i>
                            </span>
                            <input
                                type="text"
                                value={itemQty}
                                onChange={(e) => setItemQty(toNumber(e.target.value))}
                            />
                            <span
                                className="plus-btn"
                                onClick={() => {
                                    setItemQty(itemQty + 1);
                                }}
                            >
                                <i className="fas fa-plus"></i>
                            </span>
                        </div>

                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={(e) => handleAddToCart(product)}
                        >
                            <i className="fas fa-cart-plus"></i>
                            <span>
                                Add
                            </span>
                        </button>
                    </div>
                </div>);
            })()}
        </div>
    </div>);
})

export default ProductGridItem;