import { Link } from "react-router-dom";

type IProductsFilterOptions = {
    changeGridStyle: Function;
    setPageSize: Function;
    setSortBy: Function;
    pageNo: Number;
    pageSize: Number;
    totalRecords: Number;
    isMobileResolution: boolean;
};

const ProductsFilterOptions = ({ changeGridStyle, setPageSize, setSortBy, pageNo, pageSize, totalRecords, isMobileResolution }: IProductsFilterOptions) => {
    const handleGrid = (e: any, gridStyleClass: string) => {
        changeGridStyle(gridStyleClass);
        let i, aLinks;

        aLinks = document.getElementsByTagName("a");
        for (i = 0; i < aLinks.length; i++) {
            aLinks[i].className = aLinks[i].className.replace("active", "");
        }

        e.currentTarget.className += " active";
    }

    return (<div className="products-filter-options">
        <div className="row align-items-center">
            { !isMobileResolution? <div className="col d-flex">
                <span>View:</span>
                <div className="view-list-row">
                    <div className="view-column">
                        <Link to="#"
                            className="icon-view-two"
                            onClick={e => {
                                e.preventDefault();
                                handleGrid(e, "products-col-two")
                            }}>

                            <span></span>
                            <span></span>

                        </Link>

                        <Link to="#"
                            className="icon-view-three"
                            onClick={e => {
                                e.preventDefault();
                                handleGrid(e, "")
                            }}>

                            <span></span>
                            <span></span>
                            <span></span>

                        </Link>

                        <Link to="#"
                            className="icon-view-four active"
                            onClick={e => {
                                e.preventDefault();
                                handleGrid(e, "products-col-four")
                            }}>

                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>

                        <Link to="#"
                            className="icon-view-six"
                            onClick={e => {
                                e.preventDefault();
                                handleGrid(e, "products-col-six")
                            }}>

                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>

                        <Link to="#"
                            className="view-grid-switch"
                            onClick={e => {
                                e.preventDefault();
                                handleGrid(e, "products-row-view")
                            }}>

                            <span></span>
                            <span></span>

                        </Link>
                    </div>
                </div>
            </div> : null }
            
            <div className="col d-flex justify-content-center">
                <p>Showing {pageNo?.toString()} to {pageSize?.toString()} of {totalRecords?.toString()} results</p>
            </div>

            <div className="col d-flex">
                {/* <span>Show:</span>
                <div className="show-products-number">
                    <select onChange={(e) => setPageSize(e.target.value)}>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div> */}

                <span>Sort:</span>
                <div className="products-ordering-list">
                    <select onChange={(e) => setSortBy(e.target.value)}>
                        <option value="">Featured</option>                    
                        <option value="Name_ASC">Name Ascending</option>
                        <option value="Name_DESC">Name Descending</option>
                    </select>
                </div>
            </div>
        </div>
    </div>);
}

export default ProductsFilterOptions;