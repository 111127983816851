import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import _, { toNumber } from "lodash";
import { useStores } from "../../stores";
import { IOrder, DeliveryMethodEnum, ICartItem, IOrderDetail, OrderStatusEnum } from "../../stores/index.d";
import { formatDate, formatter } from "../../utilities/Functions";
import { Config } from "../../services/Config";
import { AppConfig } from "../../AppConfig";
import { useNavigate } from "react-router-dom";

const siteTitle = AppConfig?.Title;

interface Column {
    id:
    | "department"
    | "name"
    | "code"
    | "quantity"
    | "in_stock"
    | "unit_cost"
    | "sub_total";
    label: string;
    minWidth?: number;
    align?: "right";
}

let columns: Column[] = [
    { id: "department", label: "Department", minWidth: 170 },
    { id: "name", label: "Name", minWidth: 170 },
    { id: "code", label: "Sku Code", minWidth: 100 },
    {
        id: "quantity",
        label: "Quantity",
        minWidth: 170,
        align: "right",
    },
    {
        id: "in_stock",
        label: "In Stock",
        minWidth: 170,
        align: "right",
    },
    {
        id: "unit_cost",
        label: "Unit Cost",
        minWidth: 170,
        align: "right",
    },
    {
        id: "sub_total",
        label: "Sub Total",
        minWidth: 170,
        align: "right",
    },
];

function createData(
    department: string,
    name: string,
    code: string,
    quantity: number,
    in_stock: number,
    unit_cost: number,
    sub_total: number
) {
    return { department, name, code, quantity, in_stock, unit_cost, sub_total };
}

const OrderDetailsPage = observer(() => {
    const { cartStore, authStore: { getShopId, ShowShopName }, orderStore } = useStores();
    const { orderDetails, getOrderDetails, isEditOrderRequest } = orderStore;
    const { setOrderNumber, addItemToCart, setDeliveryDetails } = cartStore;
    const [data, setData] = useState({} as IOrder);
    const navigate = useNavigate();

    useEffect(() => {
        getOrderDetails(() => { });
        if (!AppConfig?.CollectInStockFromStore) {
            let removeIndex = columns?.findIndex((p) => p.id === "in_stock");
            if (removeIndex >= 0) {
                columns.splice(removeIndex, 1);
            }
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(orderDetails?.Orders) && orderDetails.Orders.length > 0) {
            setData(toJS(orderDetails)?.Orders[0]);
        }
    }, [orderDetails]);

    const {
        DateCreated,
        OrderNumber,
        OrderID,
        Note,
        OrderDetails,
        AddressLine1,
        AddressLine2,
        City,
        CountyState,
        PostalCode,
        ExpectedDate,
        DeliveryMethod,
        OrderStatusID,
        Status
    } = data;

    const rows = _.map(
        OrderDetails,
        ({ Qty, Price, TotalAmount, InStock, ProductName, SkuCode, DepartmentName }) => {
            return createData(
                DepartmentName,
                ProductName,
                SkuCode,
                Qty,
                InStock,
                Price,
                TotalAmount
            );
        }
    );

    const sub_total = _.reduce(
        OrderDetails,
        function (sum, n) {
            return sum + n.TotalAmount;
        },
        0
    );

    const handleInvoiceDownload = (e: any) => {
        e.preventDefault();
        window.open(Config.ROOT_URL + Config.REST_URIS.Order.invoiceDownload(OrderID));
    };

    useEffect(() => {
        if (OrderNumber && isEditOrderRequest)
            handelEditOrder(undefined);
    }, [OrderNumber]);

    const handelEditOrder = (e: any) => {
        e?.preventDefault();
        setOrderNumber(OrderNumber);
        setDeliveryDetails(DeliveryMethod, "dt");
        setDeliveryDetails(formatDate(ExpectedDate, "YYYY-MM-DD"), "dd");
        setDeliveryDetails(Note, "dn");
        OrderDetails.forEach((item: IOrderDetail) => {
            let cartItem = {
                ProductId: item.ProductId,
                Name: item.ProductName,
                DepartmentName: item.DepartmentName,
                SKUCode: item.SkuCode,
                inStok: item.InStock,
                SellPrice: item.Price,
                quantity: item.Qty,
            }
            addItemToCart(cartItem, false);
        });
        navigate("/cart");
    }

    return (<>
        <title>{siteTitle} - Order Details</title>
        <section className="cart-area ptb-60">
            <div className="container">
                <div className="d-flex flex-row">
                    <div className="h2">
                        <span className="badge bg-primary p-2">{getShopId()} Shop </span>
                    </div>
                    <div className="h4 w-100 p-2"> Order Details </div>
                    <span className="flex-shrink-0">
                        {OrderStatusID == OrderStatusEnum.Active ? <a href="" className="btn btn-dark" onClick={handelEditOrder} title="Edit Order"><i className="fas fa-edit"></i></a> : null}
                        <a href="" className="btn btn-dark" onClick={handleInvoiceDownload} title="Dowload Invoice"><i className="fas fa-download text-warning"></i></a>
                    </span>
                </div>
            </div>

            <div className="container">
                <div className="card">
                    <div className="card-header bg-gradient bg-primary text-white">
                        <h6>From</h6>
                        <h5>{ShowShopName()}</h5>
                        <h6>
                            {AddressLine1},{AddressLine2} {City} {CountyState} {PostalCode}
                        </h6>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col"><label className="fw-bold">Order ID:</label> {OrderID}</div>
                            <div className="col"><label className="fw-bold">Order Number:</label> {OrderNumber}</div>
                            <div className="col">
                                <><label className="fw-bold">Date Created:</label> {formatDate(DateCreated)}</>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col"><label className="fw-bold">Delivery Method:</label> {DeliveryMethodEnum[toNumber(DeliveryMethod ?? 0)] ?? "NA"}</div>
                            <div className="col"><label className="fw-bold">Expected {DeliveryMethodEnum[toNumber(DeliveryMethod ?? 1)]} Date:</label> {formatDate(ExpectedDate) ?? "NA"}</div>
                            <div className="col"><label className="fw-bold">Order Status:</label> <span className={`fw-bold ${OrderStatusID == 2 ? "text-success" : OrderStatusID == 1 ? "text-primary" : "text-info"}`}>{Status}</span></div>
                        </div>
                        <div className="row">
                            <div className="col"><label className="fw-bold">Order Notes:</label> <p>{!Note ? "NA" : Note}</p></div>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                {columns.map((column) => (
                                    <th key={column.id} scope="col">{column.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr key={row.code}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        if (column.id === "unit_cost") {
                                            return <td key={column.id}>{formatter(value)}</td>;
                                        } else if (column.id === "sub_total") {
                                            return <td key={column.id}>{formatter(value)}</td>;
                                        } else {
                                            return <td key={column.id}>{value}</td>;
                                        }
                                    })}
                                </tr>
                            ))}
                        </tbody>

                        <tfoot>
                            <tr>
                                <th colSpan={12}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col text-end">
                                                <h6>Sub-Total Amount : {formatter(sub_total)}</h6>
                                                <h4>Total : {formatter(sub_total)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="d-grid gap-2 col-lg-6 col-sm-12 mx-auto">
                    <a className="btn btn-light" href="/orders">View all Orders</a>
                    <a className="btn btn-light" href="/al-prods/all">Continue Shopping</a>
                </div>
            </div>
        </section>
    </>)
});

export default OrderDetailsPage;