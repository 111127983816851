import { useEffect } from "react";
import { useStores } from "../../stores";
import { observer } from "mobx-react";

const Loader = observer(() => {
    const { authStore } = useStores();
    const { showLoader } = authStore;

    useEffect(() => {

    }, [showLoader])

    return (
        <div style={{
            flexDirection: 'row',
            flexGrow: 1,
            flexShrink: 0,
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 20000,
            top: 0,
            backgroundColor: 'white',
            opacity: '60%',
            justifyContent: 'center',
            alignItems: 'center',
            display: showLoader ? 'flex' : 'none',
        }}>
            <div className="spinner-grow" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>

        </div>
    )
})

export default Loader;
