import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "./resources/theme/styles/fontawesome.min.css";
import "./resources/theme/styles/animate.min.css";
import "./resources/theme/styles/slick.css";
import "./resources/theme/styles/slick-theme.css";
import "./resources/theme/styles/style.css";
import "./resources/theme/styles/style-arabic.css";
import "./resources/theme/styles/responsive.css";
import './App.css';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <div className="App">
      <AppRoutes />      
    </div>
  );
}

export default App;
