import { AppConfig } from "../AppConfig";

const urlSuffix = AppConfig?.APIUrlSuffix??"";

export const Config = {
  ROOT_URL: AppConfig?.APIBaseUrl??"",
  URL_SUFFIX: urlSuffix,
  REST_URIS: {
    Auth: {
      login: `${urlSuffix}get-login-status`,
      signup:`${urlSuffix}register-user`,
      getUserStores: (userId: number, tenantId: number) =>`${urlSuffix}get-all-user-locations/${userId}/${tenantId}`,
    },

    User: {
      allUser: (date: string, no: string) => `${urlSuffix}users/${date}/${no}`,
    },

    Device: {
      ConnectionCheck: (no: string) => `${urlSuffix}connection-check/${no}`,
    },

    Product: {
      allProducts: (date: string, no: number) => `${urlSuffix}products/${date}/${no}`,
      Products: (shop: string) => `${urlSuffix}products/${shop}`,
      ProductsByAcc: (shop: string, accountId: number) => `${urlSuffix}products-by-shop/${shop}/${accountId}`,
      getFrequentProducts:(tenantId: number, warehouseId: number) => `${urlSuffix}getfrequentproducts/${tenantId}/${warehouseId}`
    },

    Order: {
      create: `${urlSuffix}create-order`,
      detailByNo: (orderNo: string,shop: string) => `${urlSuffix}orders/${orderNo}/${shop}`, 
      completedsales: (orderNumber: string) => `${urlSuffix}completesalesorders/${orderNumber}`,
      allcompletedorder: `${urlSuffix}get-all-complete-saleorders`,
      invoiceDownload: (orderId: number) => `reports/salesorderinvoiceprint/${orderId}`  
    }
  },
};
