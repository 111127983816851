import { Link } from "react-router-dom";
import { useStores } from "../../stores";
import { useEffect, useMemo, useState } from "react";
import { formatter } from "../../utilities/Functions";
import defaultImgNa from "../../resources/images/image-na-alt.png";

const LeftSidebarFilter = () => {
    const { shopStore } = useStores();
    const { departments, onSelectionDept, selected_dept, products } = shopStore;
    const [activeFilterSection, setActiveFilterSection] = useState("deps");

    useEffect(() => { if (selected_dept) setActiveFilterSection("deps"); }, [selected_dept])

    const popularProds = useMemo(() => { return products.slice(0, 4) }, [products]);

    const handleFilterWidgetToggle = (e: any, title: string) => {
        e.preventDefault();
        setActiveFilterSection(activeFilterSection === title ? "" : title)
    }

    return (<>
        <div className="woocommerce-sidebar-area">
            <div className={`collapse-widget collections-list-widget open`}>
                <h3 className={`collapse-widget-title ${activeFilterSection === "deps" ? "active" : ""}`} onClick={(e) => { handleFilterWidgetToggle(e, "deps"); }}>
                    <span id="lbl_lftfilt_category">Categories</span>
                    <i className="fas fa-angle-up"></i>
                </h3>
                <ul className={`collections-list-row ${activeFilterSection === "deps" ? "block" : "none"}`}>
                    <li key="all-1">
                        <h6 className="" onClick={() =>
                            selected_dept ? onSelectionDept(null) : null
                        }>
                            <Link to={""} className={!selected_dept ? "btn btn-dark text-white" : ""}>ALL</Link>
                        </h6>
                    </li>
                    <li key="pplr-prod-2">
                        <h6 className="" onClick={() =>
                            selected_dept !== "pplr-prod" ? onSelectionDept("pplr-prod") : null
                        }>
                            <Link to={""} className={selected_dept === 'pplr-prod' ? "btn btn-dark text-white" : ""}>Popular Products</Link>
                        </h6>
                    </li>
                    {departments?.map((item, idx) => {
                        return (
                            <li key={item + idx}>
                                <h6 className="" onClick={() =>
                                    selected_dept !== item ? onSelectionDept(item) : null
                                }>
                                    <Link to={""} className={selected_dept == item ? "btn btn-dark text-white" : ""}>{item}</Link>
                                </h6>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className={`collapse-widget collections-list-widget ${activeFilterSection != "ratings" ? 'open' : ''}`}>
                <h3 className={`collapse-widget-title ${activeFilterSection === "ratings" ? "active" : ""}`} onClick={(e) => { handleFilterWidgetToggle(e, "ratings"); }}>
                    <span id="lbl_lftfilt_rating">Rating</span>
                    <i className="fas fa-angle-up"></i>
                </h3>
                <ul className={`price-list-row ${activeFilterSection === "ratings" ? 'block' : 'none'}`}>
                    <li key={1}>
                        <a href="/en/all-products/0/all-categories">
                            <div className="rating-side-bar"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i></div>
                        </a>
                    </li>
                    <li key={2}>
                        <a href="/en/all-products/0/all-categories">
                            <div className="rating-side-bar"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="far fa-star"></i></div>
                        </a>
                    </li>
                    <li key={3}>
                        <a href="/en/all-products/0/all-categories">
                            <div className="rating-side-bar"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i></div>
                        </a>
                    </li>
                    <li key={4}>
                        <a href="/en/all-products/0/all-categories">
                            <div className="rating-side-bar"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i></div>
                        </a>
                    </li>
                    <li key={5}>
                        <a href="/en/all-products/0/all-categories">
                            <div className="rating-side-bar"><i className="fas fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i><i className="far fa-star"></i></div>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="collapse-widget aside-products-widget">
                <h3 className="aside-widget-title" id="lbl_lftfilt_pop_prod">Popular Products</h3>
                {popularProds?.map((item, idx) => {
                    return (
                        <div className="aside-single-products" key={item.ProductId}>
                            <div className="products-image">
                                <a href={"/prod/" + item.ProductId}>
                                    <img src={item?.ProductImagePath ?? defaultImgNa} alt="Product image" />
                                </a>
                            </div>
                            <div className="products-content">
                                <h3><a href={"/prod/" + item.ProductId}>{item.Name}</a></h3>
                                <div className="product-price"><span className="new-price">{formatter(item.SellPrice)}</span></div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    </>);
}

export default LeftSidebarFilter;