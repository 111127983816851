import { Link, NavLink, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import _, { unset } from "lodash";
import { useStores } from "../../stores";
import { WebStorage } from "../../utilities";
import { WEB_STORAGE_NAMES } from "../../utilities/Constants";
import { AppConfig } from "../../AppConfig";

const NavBar = observer(() => {
    const navigate = useNavigate();
    const { authStore, cartStore, shopStore, userStore } = useStores();
    const { logout, loggedUser, ShowShopName, authed, getUserId, getTenantId } = authStore;
    const { departments, onSelectionDept, selected_dept, fetchProducts } = shopStore;
    const { cartItemsCount, clearCartItems } = cartStore;
    const { storesList, getStores } = userStore;
    const [selectedStore, setSelectedStore] = useState(ShowShopName());
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [collapsed, setCollapsed] = useState(true);
    const [searchForm, setSearchForm] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const userInfo = WebStorage.getStorage(WEB_STORAGE_NAMES.UserInfo);

    useEffect(() => { }, [cartItemsCount])


    const handleStoreSelect = (warehouseId: number, warehouseTitle: any) => {
        if (!warehouseId) return;

        if (userInfo.shopId != warehouseId)
            clearCartItems();
        userInfo.shopId = warehouseId;
        userInfo.ShopName = warehouseTitle;
        WebStorage.setStorage(WEB_STORAGE_NAMES.UserInfo, userInfo);
        setSelectedStore(warehouseTitle);
        window.location.reload();
    };

    useEffect(() => {
        if (authed && userInfo?.IsMultistoreAdmin && !storesList?.length) {
            getStores(getUserId(), getTenantId());
        }
    }, [authed]);

    useEffect(() => {
        if (userInfo?.IsMultistoreAdmin && storesList?.length && !userInfo?.shopId) {
            const defaultShop = _.first(storesList);
            handleStoreSelect(defaultShop?.WarehouseId ?? 0, defaultShop?.WarehouseName);
        }
    }, [storesList])

    useEffect(() => {
        if (userInfo?.shopId)
            fetchProducts(userInfo.shopId);
    }, [userInfo?.shopId]);

    const departmentList = useMemo(() => {
        return departments;
    }, [departments]);

    useEffect(() => {
        <div className="0">0</div>
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        let element = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                element?.classList.add("is-sticky");
            } else {
                element?.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }, [])

    const handleLogout = () => {
        logout(() => {
            navigate("/");
        });
    };

    const submitSearchForm = () => {
        if (searchTerm) {
            setSearchForm(!searchForm);
            navigate("/al-prods/all?searchTerm=" + searchTerm, { replace: true });
            window.location.reload();
        }
    }

    const iconBarBtnClass = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
    const iconBarContentClass = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';

    return (
        <div className="navbar-area">
            <div id="navbar" className="comero-nav is-sticky comero-nav-dark">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <a className="navbar-brand" href="/">
                            <img src={AppConfig?.LogoURL} width="155" height="41" alt="logo" />
                        </a>

                        {authed ? <>{isMobile ? <><div className="">
                            <i
                                onClick={() => { setSearchForm(!searchForm); }}
                                className={`search-btn fas fs-5 fa-search ${searchForm ? "d-none" : "d-block"}`}
                            ></i>

                            <i
                                onClick={() => { setSearchForm(!searchForm); }}
                                className={`close-btn fas fs-5 fa-times ${searchForm ? 'active' : 'd-none'}`}
                            ></i>

                            <div style={{ width: "100%", top: "10%" }}
                                className={`search-overlay bg-secondary search-popup ${searchForm ? "d-block" : "d-none"}`}
                            >
                                <div className='search-box'>
                                    <form className="search-form">
                                        <input className="search-input" name="search" placeholder="Search" type="text"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <button className="search-button" type="button"
                                            onClick={() => submitSearchForm()}
                                        >
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                            <div className="navbar">
                                <div className="dropdown">
                                    <a href="#" onClick={() => setShowProfileMenu(!showProfileMenu)} role="button" className={`dropdown-toggle text-white ${showProfileMenu ? "show" : ""}`} id="shopProfileMv" data-bs-toggle="dropdown" aria-expanded="true">
                                        <i className="fas fa-user-alt fs-5"></i>
                                    </a>
                                    <ul id="ulShopProfileMv" style={{ width: "100%", minWidth: "fit-content" }} className={`dropdown-menu dropdown-menu-start text-center pb-2 pt-3 mt-0 ${showProfileMenu ? "show" : ""}`} aria-labelledby="shopProfileMv">
                                        <li>
                                            <a className="dropdown-item" href=""><i className="fa fa-user-circle fs-6 text-info"></i> My Profile</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/orders"><i className="fa fa-list-alt fs-6 text-primary"></i> My Orders</a>
                                        </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>{!authed ? <a href="/login" className="dropdown-item"><i className="fa fa-sign-in-alt fs-6 text-primary"></i> Login</a> : <a href="/" onClick={handleLogout}><i className="fa fa-sign-out-alt fs-6 text-warning"></i> Logout</a>}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="navbar">
                                <Link to="/cart" className="text-white">
                                    <i className="fas fa-shopping-cart position-relative fs-5"></i>
                                    <span style={{ transform: "translate(-31%,-87%)!important" }} className="badge rounded-pill bg-primary">
                                        {cartItemsCount}
                                        <span className="visually-hidden">Cart items</span>
                                    </span>
                                </Link>
                            </div>
                        </> : null}

                            <button onClick={() => setCollapsed(!collapsed)} className={iconBarBtnClass} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="icon-bar top-bar"></span><span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={iconBarContentClass} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item p-relative">
                                        <NavLink className="nav-link" id="lbl_mgmenu_home" to={"/"}>Home</NavLink>
                                    </li>
                                    <li className="nav-item p-relative">
                                        <NavLink className="nav-link" id="lbl_mgmenu_products" to={`/al-prods/all`}>All Products</NavLink>
                                    </li>
                                    <li className="nav-item megamenu">
                                        <a className="nav-link"><span id="lbl_mgmenu_category">Categories</span>  <i className="fas fa-chevron-down"></i></a>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <div className="container">
                                                    <div className="d-flex flex-wrap">
                                                        {departmentList?.length
                                                            ? departmentList.map((item, index) => (
                                                                <div key={item + index} className="flex-fill p-1">
                                                                    <Link className={`btn btn-lg text-white${selected_dept == item ? " btn-dark" : " btn-secondary"}`} onClick={() => onSelectionDept(item)} to={"/al-prods/" + item}>{item}
                                                                    </Link>
                                                                </div>
                                                            ))
                                                            : null}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <div className="others-option">
                                    {!isMobile ? <>
                                        {authed && loggedUser?.IsMultistoreAdmin ? (<>
                                            <div className="option-item">
                                                <div className="navbar">
                                                    <div className="navbar-nav">
                                                        <div className="nav-item megamenu pt-0 pt-0">
                                                            <a href="#" className="text-decoration-none dropdown-toggle" id="linkStoreSwitch" data-bs-toggle="dropdown" aria-expanded="true">
                                                                <span className="">{selectedStore ?? loggedUser.ShopName}</span>
                                                            </a>
                                                            <ul id="ulStoreSwitch" className="pb-2 pt-2 dropdown-menu dropdown-menu-start text-center" aria-labelledby="linkStoreSwitch">
                                                                <li className="h6 dropdown-title">
                                                                    Switch Stores
                                                                </li>
                                                                <li className="dropdown-item">
                                                                    <hr className="dropdown-divider" />
                                                                </li>
                                                                {storesList &&
                                                                    storesList.map((item: any, i) => (
                                                                        <li key={item + i} className="dropdown-item">
                                                                            <a className={"dropdown-item" + (selectedStore?.toString() == item.WarehouseName ? " active text-white text-decoration-none" : "")} onClick={() => handleStoreSelect(item.WarehouseId, item.WarehouseName)}>{isMobile ? item.WarehouseName.substring(0, 80) : item.WarehouseName.substring(0, 20) + "..."}</a>
                                                                        </li>
                                                                    ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        ) : <div className="option-item h6">{loggedUser.ShopName}</div>}

                                        <div className="option-item">
                                            <i
                                                onClick={() => { setSearchForm(!searchForm); }}
                                                className={`search-btn fas fa-search ${searchForm ? "d-none" : "d-block"}`}
                                            ></i>

                                            <i
                                                onClick={() => { setSearchForm(!searchForm); }}
                                                className={`close-btn fas fa-times ${searchForm ? 'active' : ''}`}
                                            ></i>

                                            <div
                                                className={`search-overlay search-popup ${searchForm ? "d-block" : "d-none"}`}
                                            >
                                                <div className='search-box'>
                                                    <form className="search-form" action="/al-prods/all">
                                                        <input className="search-input" name="searchTerm" placeholder="Search" type="text"
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />
                                                        <button className="search-button" type="button"
                                                            onClick={() => submitSearchForm()}
                                                        >
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="option-item">
                                            <div className="navbar">
                                                <div className="navbar-nav">
                                                    <div className="nav-item megamenu pt-0 pt-0">
                                                        <a href="#" className="dropdown-toggle" id="shopProfile" data-bs-toggle="dropdown" aria-expanded="true">
                                                            <i className="fa fa-user fs-6"></i>
                                                        </a>
                                                        <ul id="ulShopProfile" className="dropdown-menu dropdown-menu-start text-center pb-2 pt-2" aria-labelledby="ulShopProfile">
                                                            <li className="dropdown-item">
                                                                <a href=""><i className="fa fa-user-circle fs-6 text-info"></i> My Profile</a>
                                                            </li>
                                                            <li className="dropdown-item">
                                                                <a href="/orders"><i className="fa fa-list-alt fs-6 text-primary"></i> My Orders</a>
                                                            </li>
                                                            <li>
                                                                <hr className="dropdown-divider" />
                                                            </li>
                                                            <li className="dropdown-item">{!authed ? <a href="/login"><i className="fa fa-sign-in-alt fs-6 text-primary"></i> Login</a> : <a href="/" onClick={handleLogout}><i className="fa fa-sign-out-alt fs-6 text-warning"></i> Logout</a>}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="option-item">
                                            <Link to="/cart">Cart
                                                <i className="fas fa-shopping-cart position-relative fa-2x"></i>
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                                                    {cartItemsCount}
                                                    <span className="visually-hidden">Cart items</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </> : null}
                                </div>
                            </div></> : <>
                            <div className="ms-auto">
                                <a href="/login" className="text-white d-flex align-items-center">
                                    <i className="fa fa-sign-in-alt fa-2x"></i>
                                    <span>&nbsp;Login</span>
                                </a>
                            </div>
                        </>}
                    </nav>
                    {isMobile ? authed && loggedUser.IsMultistoreAdmin ? (<>
                        <div>
                            <a href="#" className="text-decoration-none dropdown-toggle text-white" id="linkStoreSwitchMv" data-bs-toggle="dropdown" aria-expanded="true">
                                <span className="h6">{selectedStore ?? loggedUser.ShopName}</span>
                            </a>
                            <ul id="ulStoreSwitchMv" className="dropdown-menu dropdown-menu-start text-center" aria-labelledby="linkStoreSwitchMv">
                                <li className="h6">
                                    Switch Stores
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                {storesList &&
                                    storesList.map((item: any, i) => (
                                        <li key={item + i}>
                                            <a className={"dropdown-item" + (selectedStore?.toString() == item.WarehouseName ? " active text-white text-decoration-none" : "")} onClick={() => handleStoreSelect(item.WarehouseId, item.WarehouseName)}>{isMobile ? item.WarehouseName.substring(0, 80) : item.WarehouseName.substring(0, 20) + "..."}</a>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </>
                    ) : <div className="option-item h6 text-white">{loggedUser.ShopName}</div> : null}
                </div>
            </div>
        </div>
    );
});

export default NavBar;
