import React, { useEffect, useState } from 'react';

const ScrollToTop = () => {

    const [showIcon, setShowIcon] = useState(false);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            setShowIcon(window.scrollY > 170);
        });
    }, [])

    const scrollToTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    const renderGoTopIcon = () => {
        if (showIcon) {
            return (
                <div className={`go-top`} id='linkScrollToTop' onClick={scrollToTop}>
                    <i className="fas fa-arrow-up"></i>
                    <i className="fas fa-arrow-up"></i>
                </div>
            )
        }
    }

    return (
        <>
            {renderGoTopIcon()}
        </>
    )
}

export default ScrollToTop;