import { observer } from "mobx-react";
import LeftSidebarFilter from "../../component/Shared/LeftSidebarFilter";
import { useEffect, useMemo, useState } from "react";
import { filter, includes, isEqual, orderBy } from "lodash";
import ProductGrid from "../../component/Products/ProductGrid";
import ProductsFilterOptions from "../../component/Products/ProductsFilterOptions";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import { WebStorage } from "../../utilities";
import { WEB_STORAGE_NAMES } from "../../utilities/Constants";
import { useStores } from "../../stores";
import { AppConfig } from "../../AppConfig";

const siteTitle = AppConfig?.Title;

const ProductsPage = observer(() => {
    const { shopStore } = useStores();
    const { products, selected_dept, fetchProducts, onSelectionDept } = shopStore;

    const [gridStyleClass, setGridStyleClass] = useState("products-col-four");
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [sortBy, setSortBy] = useState("");
    const [isMobileResolution, setIsMobileResolution] = useState(window.innerWidth < 768);
    const [searchTerm, setSearchTerm] = useState(new URLSearchParams(useLocation().search).get('searchTerm') ?? "");
    const { dept } = useParams();
    const userInfo = WebStorage.getStorage(WEB_STORAGE_NAMES.UserInfo);

    useEffect(() => {
        if (userInfo.shopId && products.length === 0) {
            fetchProducts();
        } else {
            onSelectionDept(dept == 'all' ? null : dept);
        }
    }, []);

    useEffect(() => {        
        const handleResize = () => {
            setIsMobileResolution(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const productList = useMemo(() => {
        setTotalRecords(products.length);

        const sortData = sortBy ? sortBy.split('_') : []
        let prods = products && sortData ? orderBy(products, [sortData[0]], [sortData[1] == "ASC" ? "asc" : "desc"]) : products;

        if (prods && selected_dept) {
            prods = selected_dept === "pplr-prods"? filter(prods, o => o.IsFrequentlyUsed === true) : filter(prods, o => isEqual(o.DepartmentName, selected_dept));
        }
        if (prods && searchTerm) {
            prods = filter(prods, o => includes(o.Name.toUpperCase(), searchTerm.toUpperCase()));
        }

        setPageSize(prods.length);
        return prods;

    }, [products, selected_dept, sortBy, searchTerm]);

    return (
        <>
            <title>{siteTitle} - All Products</title>

            <section className="products-collections-area mt-4" id="all_products_main_sec">
                <div className="container-fluid">
                    <div className="d-flex flex-row row mb-3">
                        <div className="col-sm-12 col-md-8  col-lg-9">
                            <div className="section-title mb-0">
                                {searchTerm ? <p className="fs-6">Search result for "<strong className="h4">{searchTerm}</strong>"</p> :
                                    <h2>
                                        <span className="dot"></span><span id="lbl_allprd_allproduct">All Products
                                        </span>
                                    </h2>}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-3">
                            <div className="input-group rounded">
                                <input type="search" value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)} className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                <span className="input-group-text border-0" id="search-addon">
                                    <i className="fas fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <LeftSidebarFilter />
                        </div>

                        <div className="col-lg-9 col-md-12">
                            <ProductsFilterOptions
                                changeGridStyle={(gridClass: string) => { setGridStyleClass(gridClass); }}
                                setPageSize={(s: number) => setPageSize(s)}
                                setSortBy={(s: string) => setSortBy(s)}
                                pageNo={pageNo}
                                pageSize={pageSize}
                                totalRecords={totalRecords}
                                isMobileResolution={isMobileResolution}
                            />

                            <div className={`products-collections-listing row ${ isMobileResolution ? "" : gridStyleClass}`}>
                                <ProductGrid products={productList} productCardClass="col-lg-4 col-sm-6 col-md-4 products-col-item" />
                            </div>

                            {/* {
                            showPagination == true ?
                                <SitePagination
                                    TotalRecords={TotalRecords}
                                    CurrentPage={PageNo}
                                    PageSize={PageSize}
                                    setCurrentPage={setCurrentPage}
                                />

                                :
                                <>
                                </>
                        } */}
                        </div>
                    </div>
                </div>
            </section>
        </>);
});

export default ProductsPage;