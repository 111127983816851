import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import { AppConfig } from "../../AppConfig";
import { ShowAlert } from "../../utilities/Functions";

const siteTitle = AppConfig?.Title;

export default function LoginPage() {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const { authStore } = useStores();
    const { login } = authStore;

    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!username || !password) {
            ShowAlert.Warning("Please fill out all * marked fields.");
            return;
        }

        let req = { username, md5Pass: btoa(password) };
        login(req, () => {  navigate("/"); });
    };

    return (
        <>
            <title>{siteTitle} - Login</title>

            <section className="login-area ptb-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="login-content">
                                <div className="section-title">
                                    <h2>
                                        <span className="dot"></span>
                                        <span id="lbl_login_title">
                                            Login
                                        </span>
                                    </h2>
                                </div>

                                <form className="login-form" autoComplete="off" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>
                                            Username
                                        </label>
                                        <input type="text" className="form-control" placeholder="Enter your email" id="name" name="name"
                                            required={true}
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            Password
                                        </label>
                                        <input type="password" className="form-control" placeholder="Enter your password" id="password" name="password"
                                            required={true}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    <button type="submit" className="btn btn-primary" id="lbl_login_loginbtn">
                                        Login
                                    </button>

                                    <Link to={`/${""}/reset-password`} className="forgot-password">
                                        Forgot your password?
                                    </Link>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="new-customer-content">
                                <div className="section-title">
                                    <h2><span className="dot"></span>
                                        <span id="lbl_login_newcustomer">
                                            New Customer
                                        </span>
                                    </h2>
                                </div>

                                <span>
                                    Create an Account
                                </span>
                                <p id="lbl_login_inst_singup">
                                    Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.
                                </p>
                                <Link to={`/signup`} className="btn btn-light" id="lbl_login_createacnt_2">
                                    Create an Account
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}