export default class WebStorage {
  static getStorage(key: string) {
    const keyValue = localStorage.getItem(key);
    return this.processGetData(keyValue);
  }

  static setStorage(key: string, value: any) {
    localStorage.setItem(key, this.processSetData(value));
  }

  static removeStorage(key: string) {
    localStorage.removeItem(key);
  }

  static processGetData(str: any) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  static processSetData(value: any) {
    if (typeof value === "object") {
      value = JSON.stringify(value);
    }
    return value.toString();
  }
}
