import { makeAutoObservable } from "mobx";
import { IAuthStore, ILoggedUser, IRootStore } from "./index.d";
import { Api } from "../services";
import { Config } from "../services/Config";
import { WebStorage } from "../utilities";
import { WEB_STORAGE_NAMES } from "../utilities/Constants";
import { ShowAlert } from "../utilities/Functions";
import { AppConfig } from "../AppConfig"
import { isNumber } from "lodash";
const LOGIN = Config.REST_URIS.Auth.login;
const SIGNUP = Config.REST_URIS.Auth.signup;

export default class AuthStore implements IAuthStore {
  private rootStore: IRootStore;

  authed = false;
  loggedUser = {} as ILoggedUser;
  showLoader = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    this.getUserInfo();
  }

  getUserInfo = () => {
    const loggedUser = WebStorage.getStorage(WEB_STORAGE_NAMES.UserInfo);
    if (loggedUser) {
      this.authed = true;
      this.loggedUser = loggedUser;
    }
  };

  login = (req: object, callback: Function) => {
    let modal = AppConfig?.UserAuthRequireWarehouseId ? {
      ...req,
      serialNo: this.rootStore.SerialNo,
      tenantId: AppConfig?.TenantId,
    } : req;

    Api.post(LOGIN, modal, (res: any) => {
      if (res?.UserId) {
        this.authed = true;
        this.loggedUser = res;
        WebStorage.setStorage(WEB_STORAGE_NAMES.UserInfo, res);
        callback();
      } else {
        ShowAlert.Error(res?.Message ?? "Login request faild. Please verify your credentials are valid.");
      }
    });
  };

  logout = (callback: Function) => {
    WebStorage.removeStorage(WEB_STORAGE_NAMES.UserInfo);
    WebStorage.removeStorage(WEB_STORAGE_NAMES.StoresList);

    this.loggedUser = {} as ILoggedUser;
    this.authed = false;
    callback();
  };

  signup = (req: object, callback: Function) => {
    Api.post(SIGNUP, req, (res: any) => {
      if (isNumber(res) && res > 0) {
        callback(res);
      } else {
        ShowAlert.Error(res?.Message ?? "Request has been faild. Please try again.");
      }
    });
  }

  getShopId = () => {
    return this.loggedUser.shopId ?? 0;
  };

  ShowShopName = () => {
    return this.loggedUser.ShopName;
  };

  getUserId = () => {
    return this.loggedUser.UserId;
  };

  getTenantId = () => {
    return this.loggedUser.TenantId ?? AppConfig?.TenantId;
  };

  getAccountId = () => {
    return this.loggedUser.AccountId;
  }

  toggleLoader = (show: boolean) => {
    this.showLoader = show;
  }
}
