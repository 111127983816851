import axios from "axios";
import { Config } from "./Config";

const baseURL = Config.ROOT_URL + "api/";

const defaultHeaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Max-Age": "1800",
  "Access-Control-Allow-Headers": "content-type, Authorization",
  "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTIONS",
};

axios.interceptors.request.use(
  function (config) {
    // document.body.classList.add("loading-indicator");
    return config;
  },
  function (error) {
    // document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    // document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);
export default class Api {
  static setDefaults() {
    const d = axios.defaults;
    d.baseURL = baseURL;
    this.transformResponse();
  }

  static transformResponse() {
    return {
      transformResponse: [
        (data: any) => {
          return data ? JSON.parse(data) : {};
        },
      ],
    };
  }

  static handleStatus(callback: Function, response: any) {
    const result = response;
    if (response.status !== 200) {
      this.handleException(response, callback);
    } else {
      this.handleResponse(callback, result.data);
    }
  }

  static handleResponse(callback: Function, response: any) {
    callback(response);
  }

  static handleException(ex: any, callback: Function) {
    console.log(ex);
    callback(ex.response?.data);
  }

  static async get(api: string, callback: Function) {
    await axios
      .get(baseURL + api, {
        headers: defaultHeaders,
      })
      .then((response) => {
        this.handleStatus(callback, response);
      })
      .catch((ex) => {
        this.handleException(ex, callback);
      });
  }

  static async post(api: string, params: any, callback: Function) {
    let url = baseURL + api;
    await axios
      .post(url, params, {
        headers: defaultHeaders,
      })
      .then((response) => {
        this.handleStatus(callback, response);
      })
      .catch((ex) => {
        this.handleException(ex, callback);
      });
  }

  static async put(api: string, params: object, callback: Function) {
    await axios
      .put(api, params, {
        headers: defaultHeaders,
      })
      .then((response) => {
        this.handleStatus(callback, response);
      })
      .catch((ex) => {
        this.handleException(ex, callback);
      });
  }

  static async delete(api: string, params: object, callback: Function) {
    await axios
      .delete(api, {
        headers: defaultHeaders,
      })
      .then((response) => {
        this.handleStatus(callback, response);
      })
      .catch((ex) => {
        this.handleException(ex, callback);
      });
  }
}
