import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toNumber } from "lodash";
import RelatedProducts from "../../component/Products/RelatedProducts";
import { ICartItem, IProduct } from "../../stores/index.d";
import { useStores } from "../../stores";
import { formatter } from "../../utilities/Functions";
import { AppConfig } from "../../AppConfig";
import defaultImgNa from "../../resources/images/image-na-alt.png";
import palletImg from "../../resources/images/pallet.png";

const siteTitle = AppConfig?.Title;

const ProductDetailsPage = observer(() => {
    const navigate = useNavigate();
    const { shopStore, cartStore, authStore } = useStores();
    const { products, getProduct, fetchProducts } = shopStore;
    const { getCartItem, addItemToCart } = cartStore;
    const { toggleLoader } = authStore
    const { id } = useParams();
    const [relatedProducts, setRelatedProducts] = useState<IProduct[]>([]);
    const [itemQty, setItemQty] = useState(1);
    const [isMobileResolution, setIsMobileResolution] = useState(window.innerWidth < 768);
    const prodId = toNumber(id);
    // const [isProductInCart, setProductInCart] = useState(false);

    useEffect(() => {
        if (products.length == 0) {
            fetchProducts();
        }
    }, []);

    const productDetail = useMemo(() => {
        let prod = getProduct(prodId);
        if (!prod)
            navigate("/al-prods");

        setRelatedProducts(products.filter(p => p.ProductId != prodId && p.DepartmentName == prod?.DepartmentName));
        // let cartItem = getCartItem(prodId);
        // setProductInCart(cartItem != null);
        return prod;
    }, [products, id])

    useEffect(() => { toggleLoader(!productDetail); }, [productDetail]);

    const handleAddToCart = (prod: any) => {
        let cartItem: ICartItem = getCartItem(prod.ProductId);
        if (cartItem) {
            cartItem.quantity += itemQty;
        } else {
            cartItem = prod;
            cartItem.quantity = itemQty;
        }

        addItemToCart(cartItem, true);
        setItemQty(1);
    }

    useEffect(() => {        
        const handleResize = () => {
            setIsMobileResolution(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (<>
        <title>{siteTitle} - {productDetail?.Name}</title>

        <section className="products-details-area mt-3">
            <div className="container">
                <div className="row">
                    <h4>Product Details</h4>
                    <hr></hr>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-product-box">
                            <div className="product-image">
                                <img src={productDetail?.ProductImagePath ?? defaultImgNa} alt="Product image" />
                                {productDetail?.ProcessByPallet ? <div className="pallet-img-holder" role="button" onClick={(e: any) => {
                                    e.preventDefault();
                                    const palletQty = toNumber(productDetail?.CasesPerPallet ?? 0);
                                    if (palletQty)
                                        setItemQty(palletQty);
                                }}>
                                    <img src={productDetail?.ProductImagePath ?? palletImg} alt="Pallet image" className="pallet-img" />
                                    <span className="position-absolute top-0 start-50 badge translate-middle bg-dark-red fs-6 fw-normal">
                                        {productDetail?.CasesPerPallet ?? 0}
                                        <span className="visually-hidden">Pallet Cases</span>
                                    </span>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-left">
                        <div className="product-details-content">
                            <h3>{productDetail?.Name}</h3>
                            <div className="price">
                                <span className="new-price">
                                    {formatter(productDetail?.SellPrice)}
                                </span>
                            </div>

                            <p>
                                {productDetail?.Description}
                            </p>

                            <ul className="product-info">
                                <li><span>Category:</span> {productDetail?.DepartmentName}</li>
                                <li><span>SKU Code:</span> {productDetail?.SKUCode}</li>
                            </ul>
                            <div className="product-add-to-cart mt-4">
                                <div className="input-counter">
                                    <span
                                        className="minus-btn"
                                        onClick={() => {
                                            if (itemQty > 1)
                                                setItemQty(itemQty - 1);
                                        }}
                                    >
                                        <i className="fas fa-minus"></i>
                                    </span>
                                    <input
                                        type="text"
                                        value={itemQty}
                                        onChange={(e) => setItemQty(toNumber(e.target.value))}
                                    />
                                    <span
                                        className="plus-btn"
                                        onClick={() => {
                                            setItemQty(itemQty + 1);
                                        }}
                                    >
                                        <i className="fas fa-plus"></i>
                                    </span>
                                </div>

                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        handleAddToCart(productDetail);
                                    }}
                                >
                                    <i className="fas fa-cart-plus"></i>
                                    <span id="lbl_prd_det_addcart">
                                        Add to Cart
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <RelatedProducts products={relatedProducts} sectionHeader="Related Products" gridStyleClass={isMobileResolution ? "" : "products-col-six"} />
    </>);
})

export default ProductDetailsPage;