import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./component/Shared/NavBar";
import Footer from "./component/Shared/Footer";
import Loader from "./component/Shared/Loader";
import ProductsPage from "./pages/Products/ProductsPage";
import ProductDetailsPage from "./pages/Products/ProductDetailsPage";
import CartViewPage from "./pages/Checkout/CartViewPage";
import OrderDetailsPage from "./pages/Orders/OrderDetailsPage";
import RequireAuth from "./component/Shared/RequireAuth";
import LoginPage from "./pages/Account/LoginPage";
import { ToastContainer } from "react-toastify";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import CompletedOrdersPage from "./pages/Orders/CompletedOrdersPage";
import SignupPage from "./pages/Account/SignupPage";


function AppRoutes() {

  return (
    <BrowserRouter>
      <NavBar></NavBar>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <DashboardPage />
            </RequireAuth>
          }
        />
        
        <Route
          path="/al-prods/:dept"
          element={
            <RequireAuth>
              <ProductsPage />
            </RequireAuth>
          }
        />

        <Route
          path="/prod/:id"
          element={
            <RequireAuth>
              <ProductDetailsPage />
            </RequireAuth>
          }
        />

        <Route
          path="/cart"
          element={
            <RequireAuth>
              <CartViewPage />
            </RequireAuth>
          }
        />
        <Route
          path="/order"
          element={
            <RequireAuth>
              <OrderDetailsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/orders"
          element={
            <RequireAuth>
              <CompletedOrdersPage />
            </RequireAuth>
          }
        />
      </Routes>
      <Footer />
      <Loader />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default AppRoutes;