export interface IRootStore {
  shopStore: IShopStore;
  cartStore: ICartStore;
  authStore: IAuthStore;
  userStore: IUserStore;
  orderStore: IOrderStore;
  SerialNo: string;
  Shop: string;
}

export interface IShopStore {
  products: Array<IProduct>;
  departments: Array<string>;
  selected_dept: string | null;
  fetchProducts: Function;
  onSelectionDept: Function;
  getProduct: Function;
  getProductBySku: Function;
}

export interface IUserStore {
  users: Array<IUser>;
  fetchUsers: Function;
  verifyDevice: Function;
  getStores: Function;
  storesList:Array<IStore>;
}

export interface IAuthStore {
  authed: boolean;
  loggedUser: ILoggedUser;
  login: Function;
  logout: Function;
  signup: Function;
  getShopId: Funtion;
  getUserId: Funtion;
  getTenantId: Funtion;
  getAccountId: Funtion;
  ShowShopName: Function;
  toggleLoader: Function;
  showLoader: boolean;
}

export interface IOrderStore {
  sales: Array<ISale>;
  fetchCompletedSales: Function;
  totalCount: number;
  fetchAllSalesComplete: Function;
  getOrderDetails: Function;
  orderDetails: any;
  setOrderNumber: Function;
  isEditOrderRequest: boolean;
}

export interface ILoggedUser {
  AccountId: number | null;
  EmailVerified: boolean;
  ErrorMessage: string;
  MobileNumberVerified: boolean;
  PersonalReferralCode: number | null;
  ReferralConfirmed: boolean;
  IsMultistoreAdmin:boolean;
  ShopName: string;
  Success: boolean;
  UserEmail: string;
  UserFirstName: string;
  UserId: number;
  UserLastName: number | null;
  UserMobileNumber: boolean;
  UserName: string;
  VerificationRequired: boolean;
  shopId: number;
  TenantId: number;
}

export interface ICartStore { 
  orderNumber: string;
  setOrderNumber: Function;
  orderNotes: string;
  cartItemsCount: number;
  addItemToCart: Function;
  cartItems: ICartItem[];
  getCartItem: Function;
  clearCartItems: Function;
  expectedDeliveryDate: string;
  deliveryMethod: DeliveryMethodEnum;
  checkoutCartItems: Function;
  setDeliveryDetails: Function;
}

export interface ISale {
  AccountCode: string;
  CompanyName: string;
  OrderID: number;
  OrderNumber: string;
  OrderNumber: string;
  OrderTotal: string;
  OrderPlacedDate: string;
  Status: string;
  ProductsCount: string;
}

export interface ICartItem extends IProduct {
  productId: number;
  quantity: number;
  inStock: number;
  orderId: number;
  orderNotes: string;
  userId: number;
}

export interface IUser {
  id: number;
  name: string;
  image: string;
}
export interface IStore {

  WarehouseId: number;
  TenantId: number;
  WarehouseName: string;
 
}

export interface IProduct {
  AllowModifyPrice: Boolean;
  AllowZeroSale: Boolean;
  BarCode: string;
  BarCode2: string;
  BuyPrice: number;
  CasesPerPallet: string;
  CommodityClass: string;
  CommodityCode: string;
  DepartmentName: string;
  Description: string;
  DiscontDate: Date;
  Discontinued: Boolean;
  EnableTax: Boolean;
  EnableWarranty: Boolean;
  IsActive: Boolean;
  IsDeleted: Boolean;
  IsFrequentlyUsed: Boolean;
  IsRawMaterial: Boolean;
  IsStockItem: Boolean;
  LandedCost: number;
  MainImage: string;
  MinThresholdPrice: number;
  Name: string;
  PercentMargin: number;
  ProcessByCase: Boolean;
  ProcessByPallet: Boolean;
  ProdStartDate: Date;
  ProductCategoryName: string;
  ProductGroupName: string;
  ProductId: number;
  ProductKitMapViewModelList: string;
  ProductNotes: string;
  ProductTags: string;
  ProductType: number;
  ProductsPerCase: number;
  ReorderQty: number;
  RequiresBatchNumberOnReceipt: Boolean;
  RequiresExpiryDateOnReceipt: Boolean;
  SKUCode: string;
  SecondCode: string;
  SellPrice: number;
  Serialisable: Boolean;
  ShelfLifeDays: number;
  ShipConditionCode: string;
  SortOrder: number;
  TaxID: number;
  TaxPercent: number;
  ProductImagePath: string;
}

export interface IOrder {
  OrderDetails: Array<IOrderDetail>;

  ProductsWithoutEnoughStock: any;

  OrderID: number;
  OrderNumber: string;
  IssueDate: Date;
  ExpectedDate: Date;
  Note: string;
  InventoryTransactionTypeId: number;

  AccountID: number;
  JobTypeId: number;
  DateCreated: Date;
  DateUpdated: Date;
  CancelDate: Date;
  ConfirmDate: Date;
  CreatedBy: number;
  UpdatedBy: number;
  ConfirmBy: number;
  CancelBy: number;
  TenentId: number;
  IsCancel: Boolean;
  IsActive: Boolean;
  IsDeleted: Boolean;
  OrderStatusID: number;
  Status: string;
  WarehouseName: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  CountyState: string;
  PostalCode: string;
  LoanID: number;
  // Account contact person for this specific order
  AccountContactId: number;
  OrderTotal: number;
  // flag for Posted into accounting software eg. Sage
  Posted: Boolean;
  InvoiceNo: string;
  InvoiceDetails: string;
  OrderCost;
  ReportTypeId: number;
  ReportTypeChargeId: number;
  TransferWarehouseId: number;
  TransferWarehouseName: string;
  DepartmentId: number;
  SLAPriorityId: number;
  ExpectedHours: number;
  AuthorisedDate: Date;
  AuthorisedUserID: number;
  AuthorisedNotes: string;
  WarehouseId: number;
  DeliverectChannelLinkId: string;
  DeliverectChannel: string;
  ShipmentAddressLine1: string;
  ShipmentAddressLine2: string;
  ShipmentAddressLine3: string;
  ShipmentAddressTown: string;
  ShipmentAddressPostcode: string;
  PPropertyId: number;
  ShipmentPropertyId: number;
  OrderGroupToken: number;
  ShipmentWarehouseId: number;
  IsShippedToTenantMainLocation: Boolean;
  CustomEmailRecipient: string;
  CustomCCEmailRecipient: string;
  CustomBCCEmailRecipient: string;
  AccountCurrencyID: number;
  JobSubTypeId: number;
  RequestStatus: string;
  RequestSuccess: Boolean;
  SerialProcessStatus: any;
  DirectShip: Boolean;
  AmountPaidByAccount: number;
  AccountBalanceBeforePayment: number;
  AccountBalanceOnPayment: number;
  AccountPaymentModeId: number;
  OrderPaid: Boolean;
  FoodOrderType: number;
  EndOfDayGenerated: Boolean;
  VanSalesDailyCashId: number;
  OrderDiscount: number;
  OrderToken;
  PickerId: number;
  FullName: string;
  MobileNumber: string;
  OfflineSale: Boolean;
  VoucherCode: string;
  PaypalBraintreeNonce: string;
  DeliveryMethod: number;
}

export interface IOrderDetail {
  processed: any;
  ProcessByPallet: boolean;
  SkuCode: string;
  Barcode: string;
  ProductName: string;
  OrderDetailID: number;
  OrderID: number;
  OrderId: number;
  WarehouseId: number;
  ExpectedDate: Date;
  Notes: string;
  ProductId: number;
  ProdAccCodeID: number;
  Qty: number;
  InStock: any;
  Price: number;
  WarrantyID: number;
  WarrantyAmount: number;
  TaxID: number;
  TaxAmount: number;
  TotalAmount: number;
  DateCreated: Date;
  DateUpdated: Date;
  CreatedBy: number;
  UpdatedBy: number;
  TenentId: number;
  IsDeleted: boolean;
  OrderDetailStatusId: number;
  SortOrder: number;
  ProductAttributeValueId: number;
  ProductAttributeValueName: string;
  QuantityProcessed: number;
  OrderDetails: IOrder,
  DepartmentName: string;
}

// export interface IFrequentProduct {
//   ProductId: number,
//   Name: string,
//   SKUCode: string,
//   LessThan25Count: number,
//   PartPalletsCount: number
// }

//Start - Enums
export enum DeliveryMethodEnum {
  Delivery = 1,
  Collection = 2
}

export enum OrderStatusEnum {
  Active = 1,
  Complete = 2,
  Hold = 3
}
//End